<template>
  <div class="text-center">
    <v-dialog v-model="showUpdateEventModal" persistent max-width="1000px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Update Event</span>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form
              ref="eventForm"
              v-model="valid"
              lazy-validation
            >
              <v-row>
                <v-col cols="12">
                  <v-text-field label="Name *" :rules="[v => !!v || 'Name is required']" v-model="editEventForm.name" required></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-file-input
                    label="Thumb *"
                    dense
                    :rules="[v => !!v || 'Thumb is required',v => (v && v.size > 0) || 'Thumb is required']"
                    v-model="editEventForm.thumb"
                    prepend-icon="mdi-camera"
                    ref="thumbFileUpload"
                    @change="previewImage('thumb')"
                  ></v-file-input>
                  <v-img
                    v-if="previewThumbImage"
                    :lazy-src="previewThumbImage"
                    max-height="150"
                    max-width="250"
                    :src="previewThumbImage"
                  ></v-img>
                </v-col>
                <v-col cols="6">
                  <v-file-input
                    label="Background Banner *"
                    dense
                    :rules="[v => !!v || 'Background Banner is required',v => (v && v.size > 0) || 'Background Banner is required']"
                    v-model="editEventForm.backgroundBanner"
                    prepend-icon="mdi-camera"
                    ref="bannerFileUpload"
                    @change="previewImage('backgroundBanner')"
                  ></v-file-input>
                  <v-img
                    v-if="previewBannerImage"
                    :lazy-src="previewBannerImage"
                    max-height="150"
                    max-width="250"
                    :src="previewBannerImage"
                  ></v-img>
                </v-col>
                <v-col cols="6">
                  <v-combobox
                    :items="allType"
                    label="Type"
                    v-model="editEventForm.type"
                    required
                    chips
                  >
                  <template v-slot:item="{item}">
                    {{item.charAt(0).toUpperCase() + item.slice(1)}}
                  </template>
                  </v-combobox>
                </v-col>
                <v-col cols="6">
                  <v-combobox
                    :items="allReligion"
                    label="Religion"
                    v-model="editEventForm.religion"
                    required
                    chips
                  >
                  <template v-slot:item="{item}">
                    {{item.charAt(0).toUpperCase() + item.slice(1)}}
                  </template>
                  </v-combobox>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="resetAddEventModal()">
            Close
          </v-btn>
          <v-btn color="primary" @click="saveEvent()"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <snackbar
      :text="snackbarText"
      :showSnackbar="isShowSnackbar"
      @hide="isShowSnackbar = false"
    ></snackbar>
  </div>
</template>
<script>
import axios from "axios";
import Snackbar from "../../Snackbar.vue";
export default {
  name: "updateEvent",
  components: {
    Snackbar,
  },
  props: {
    showModel: {
      type: Boolean,
      default: false,
    },
    eventDetail: {
      type: Object,
    },
    imageBase:{
      type: String
    },
    allType:{
      type: Array,
      default: null,
    },
    allReligion:{
      type: Array,
      default: null,
    }
  },
  data: () => ({
    editEventForm:{
      name:'',
      thumb: [],
      backgroundBanner: [],
      type: '',
      religion:'',
    },
    valid: true,
    previewThumbImage: '',
    previewBannerImage: '',
    showUpdateEventModal:false
  }),
  watch: {
    showModel(val) {
      this.showUpdateEventModal = val;
    },
    async eventDetail(val){
      this.editEventForm = val;
      if(this.editEventForm.thumb){
        var eventThumbUrl = this.imageBase + this.editEventForm.thumb;
        var thumbImgName =  eventThumbUrl.split(/[#?]/)[0].split("/").pop().trim();
        await fetch(eventThumbUrl)
        .then(async response => {
          const contentType = response.headers.get('content-type')
          const blob = await response.blob()
          const file = new File([blob], thumbImgName, { contentType });
          this.editEventForm.thumb = file;
          this.$refs.thumbFileUpload.value = file;
          this.previewImage('thumb');
          console.clear();
        })
      }

      if(this.editEventForm.backgroundBanner){
        var eventBannerUrl = this.imageBase + this.editEventForm.backgroundBanner;
        var bannerImgName =  eventBannerUrl.split(/[#?]/)[0].split("/").pop().trim();
        await fetch(eventBannerUrl)
        .then(async response => {
          const contentType = response.headers.get('content-type')
          const blob = await response.blob()
          const file = new File([blob], bannerImgName, { contentType });
          this.editEventForm.backgroundBanner = file;
          this.$refs.bannerFileUpload.value = file;
          this.previewImage('backgroundBanner');
          console.clear();
        })
      }
    },
  },
  computed: {},
  mounted: function () {
  },
  created: function () {},
  methods: {
    resetAddEventModal() {
      this.editEventForm.name = '',
      this.editEventForm.thumb = [],
      this.editEventForm.backgroundBanner = [],
      this.editEventForm.type = '',
      this.editEventForm.religion = '',
      this.previewThumbImage = '',
      this.previewBannerImage = '',
      this.$emit('close');
    },
    saveEvent() {
      if (this.$refs.eventForm.validate()) {
        let formData = new FormData();
        formData.append('name', this.editEventForm.name);
        formData.append('thumb', this.editEventForm.thumb);
        formData.append('backgroundBanner', this.editEventForm.backgroundBanner);
        formData.append('type', this.editEventForm.type);
        formData.append('religion', this.editEventForm.religion);
        formData.append('_id', this.editEventForm._id);
        axios.put(this.Node_JS_HOST + "/api/v1/web/banner-master/event",formData,{
          headers: {'content-type': 'multipart/form-data'},
        }).then((res) => {
            if (res) {
              this.resetAddEventModal();
              this.snackbarText = "Event update Successfully";
              this.isShowSnackbar = true;
            }
          })
          .catch((error) => {
            this.errorHandle(error);
            this.snackbarText = "Please insert valid input";
            this.isShowSnackbar = true;
          });
      }
    },
    setFileInput(file){
      this.editEventForm.thumb = file;
      this.$refs.thumbFileUpload.value = file;
      console.clear();
    },
    previewImage: function(fileInput) {
      var reader = new FileReader();
      if(fileInput === 'thumb'){
        if(this.editEventForm.thumb){
          reader.onload = (e) => {
            this.previewThumbImage = e.target.result;
          }
          reader.readAsDataURL(this.editEventForm.thumb);
        }else{
          this.previewThumbImage = '';
        }
      }
      if(fileInput === 'backgroundBanner'){
        if(this.editEventForm.backgroundBanner){
          reader.onload = (e) => {
            this.previewBannerImage = e.target.result;
          }
          reader.readAsDataURL(this.editEventForm.backgroundBanner);
        }else{
          this.previewBannerImage = '';
        }
      }
    },
  },
};
</script>