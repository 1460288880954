<template>
  <div class="text-center">
    <v-dialog v-model="showDeleteModel" persistent max-width="400px">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ headerText }}</span>
        </v-card-title>
        <v-card-text>
          <v-container v-html="'<p>'+ contentText +'</p>'"></v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="action('close')">
            Close
          </v-btn>
          <v-btn color="primary" @click="action('delete')"> Delete </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  name: "DeleteDialog",
  props: {
    showModel: {
      type: Boolean,
      default: false,
    },
    contentText: {
      type: String,
      default: "",
    },
    headerText: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    showDeleteModel: false,
  }),
  watch: {
    showModel(val) {
      this.showDeleteModel = val;
    },
  },
  computed: {},
  mounted: function () {},
  created: function () {},
  methods: {
    action: function (eventType) {
      this.$emit("action", eventType,this.id);
    },
  },
};
</script>