<template>
  <div class="text-center">
    <v-dialog v-model="showModel" persistent max-width="1000px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Add Event</span>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form
              ref="eventForm"
              v-model="valid"
              lazy-validation
            >
              <v-row>
                <v-col cols="12">
                  <v-text-field label="Name *" :rules="[v => !!v || 'Name is required']" v-model="addEventForm.name" required></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-file-input
                    label="Thumb *"
                    dense
                    :rules="[v => !!v || 'Thumb is required',v => (v && v.size > 0) || 'Thumb is required']"
                    v-model="addEventForm.thumb"
                    prepend-icon="mdi-camera"
                    @change="previewImage('thumb')"
                  ></v-file-input>
                  <v-img
                    v-if="previewThumbImage"
                    :lazy-src="previewThumbImage"
                    max-height="150"
                    max-width="250"
                    :src="previewThumbImage"
                  ></v-img>
                </v-col>
                <v-col cols="6">
                  <v-file-input
                    label="Background Banner *"
                    dense
                    :rules="[v => !!v || 'Background Banner is required',v => (v && v.size > 0) || 'Background Banner is required']"
                    v-model="addEventForm.backgroundBanner"
                    prepend-icon="mdi-camera"
                    @change="previewImage('backgroundBanner')"
                  ></v-file-input>
                  <v-img
                    v-if="previewBannerImage"
                    :lazy-src="previewBannerImage"
                    max-height="150"
                    max-width="250"
                    :src="previewBannerImage"
                  ></v-img>
                </v-col>
                <v-col cols="6">
                  <v-combobox
                    :items="allType"
                    label="Type"
                    v-model="addEventForm.type"
                    required
                    chips
                  >
                  <template v-slot:item="{item}">
                    {{item.charAt(0).toUpperCase() + item.slice(1)}}
                  </template>
                  </v-combobox>
                </v-col>
                <v-col cols="6">
                  <v-combobox
                    :items="allReligion"
                    label="Religion"
                    v-model="addEventForm.religion"
                    required
                    chips
                  >
                  <template v-slot:item="{item}">
                    {{item.charAt(0).toUpperCase() + item.slice(1)}}
                  </template>
                  </v-combobox>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="resetAddEventModal()">
            Close
          </v-btn>
          <v-btn color="primary" @click="saveEvent()"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <snackbar
      :text="snackbarText"
      :showSnackbar="isShowSnackbar"
      @hide="isShowSnackbar = false"
    ></snackbar>
  </div>
</template>
<script>
import axios from "axios";
import Snackbar from "../../Snackbar.vue";
export default {
  name: "createEvent",
  components: {
    Snackbar,
  },
  props: {
    showModel: {
      type: Boolean,
      default: false,
    },
    allType:{
      type: Array,
      default: null,
    },
    allReligion:{
      type: Array,
      default: null,
    }
  },
  data: () => ({
    addEventForm:{
      name:'',
      thumb: [],
      backgroundBanner: [],
      type: '',
      religion:'',
    },
    valid: true,
    previewThumbImage: '',
    previewBannerImage: '',
  }),
  watch: { },
  computed: {},
  mounted: function () {
  },
  created: function () {},
  methods: {
    resetAddEventModal() {
      this.addEventForm.name = '',
      this.addEventForm.thumb = [],
      this.addEventForm.backgroundBanner = [],
      this.addEventForm.type = '',
      this.addEventForm.religion = '',
      this.previewThumbImage = '',
      this.previewBannerImage = '',
      this.$emit('close');
    },
    saveEvent() {
      if (this.$refs.eventForm.validate()) {
        let formData = new FormData();
        formData.append('name', this.addEventForm.name);
        formData.append('thumb', this.addEventForm.thumb);
        formData.append('backgroundBanner', this.addEventForm.backgroundBanner);
        formData.append('type', this.addEventForm.type);
        formData.append('religion', this.addEventForm.religion);
        axios.post(this.Node_JS_HOST + "/api/v1/web/banner-master/event",formData,{
          headers: {'content-type': 'multipart/form-data'},
        }).then((res) => {
            if (res.data.data._id) {
              this.resetAddEventModal();
              this.snackbarText = "Event add Successfully";
            }
            else{
              this.snackbarText =res.data.message;
            }
            this.isShowSnackbar = true;
          })
          .catch((error) => {
            this.errorHandle(error);
            this.snackbarText = "Please insert valid input";
            this.isShowSnackbar = true;
          });
      }
    },
    previewImage: function(fileInput) {
      var reader = new FileReader();
      if(fileInput === 'thumb'){
        if(this.addEventForm.thumb){
          reader.onload = (e) => {
            this.previewThumbImage = e.target.result;
          }
          reader.readAsDataURL(this.addEventForm.thumb);
        }else{
          this.previewThumbImage = '';
        }
      }
      if(fileInput === 'backgroundBanner'){
        if(this.addEventForm.backgroundBanner){
          reader.onload = (e) => {
            this.previewBannerImage = e.target.result;
          }
          reader.readAsDataURL(this.addEventForm.backgroundBanner);
        }else{
          this.previewBannerImage = '';
        }
      }
    },
  },
};
</script>