<template>
  <v-container fluid class="down-top-padding">
    <v-row>
      <v-col cols="12" sm="12">
        <v-card class="mb-7">
          <v-toolbar flat>
            <v-toolbar-title>Event List</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
              @click="addEventModal = true"
              class="mx-2"
              fab
              dark
              small
              color="#1976d2"
            >
              <v-icon dark> mdi-plus </v-icon>
            </v-btn>
          </v-toolbar>
          <v-divider></v-divider>
          <div class="pa-4">
            <v-data-table
              class="mx-5"
              :loading="tableLoading"
              loading-text="Loading... Please wait"
              :headers="headers"
              :search="search"
              :items="allEvent"
              :footer-props="{
                'items-per-page-options': [50, 100, 150, 200, 300, -1]
              }"
              :items-per-page="50"
              :custom-filter="filterOnlyCapsText"
            >
              <template v-slot:top>
                <v-text-field
                  v-model="search"
                  label="Search (Event Details)"
                  class="mx-4"
                ></v-text-field>
              </template>
              <template v-slot:item="row">
                <tr>
                  <td>{{ row.item.name }}</td>
                  <td>
                    <div class="d-flex"> 
                      <a @click="showImageModel(row.item.thumb)" style="text-decoration: none;">
                        <v-img :src="imageBase + row.item.thumb" :alt="row.item.name" height="25px" width="25px"></v-img>
                      </a>
                      <span class="ml-2">
                        <v-img :src="s3ImageBase + row.item.thumb" :alt="'preview-cdn'" height="25px" width="25px"></v-img>
                      </span>
                    </div>
                  </td>
                  <td>
                    <div class="d-flex"> 
                      <a @click="showImageModel(row.item.backgroundBanner)" style="text-decoration: none;">
                        <v-img :src="imageBase + row.item.backgroundBanner" :alt="row.item.name" height="25px" width="25px"></v-img>
                      </a>
                      <span class="ml-2">
                        <v-img :src="s3ImageBase + row.item.backgroundBanner" :alt="'preview-cdn'" height="25px" width="25px"></v-img>
                      </span>
                    </div> 
                  </td>
                  <td>{{ (row.item.type)? (row.item.type).charAt(0).toUpperCase() + (row.item.type).slice(1) : '-'  }}</td>
                  <td>{{ (row.item.religion) ? (row.item.religion).charAt(0).toUpperCase() + (row.item.religion).slice(1) : '-' }}</td>
                  <td>{{ dateFormat(row.item.created_date) }}</td>
                  <td><a @click="UpdateEventModel(row.item)"><v-icon color="primary">mdi-pencil</v-icon></a> | 
                      <a @click="showDeleteDilog(row.item)"><v-icon color="red">mdi-delete</v-icon></a></td>
                </tr>
              </template>
              <template v-slot:no-results>NO RESULTS HERE</template>
            </v-data-table>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <snackbar :text="snackbarText" :showSnackbar="isShowSnackbar" @hide="isShowSnackbar = false"></snackbar>
    <create-event :show-model="addEventModal" :all-type="allType" :all-religion="allReligion" @close="closeEventModal"></create-event>
    <update-event :show-model="updateEventModal" :all-type="allType" :all-religion="allReligion" @close="closeEventModal" :event-detail="updateEventDetail" :image-base="imageBase" ></update-event>
    <delete-dialog :contentText="deleteText" :showModel="showDeleteModel" :id="deleteId" :headerText="'Delete Event'" @action="deleteAction"></delete-dialog>
    <image-model :imageUrl="imageFullUrl" :showModel="isShowImageModel" :headerText="'Image'" @hide="isShowImageModel = false"></image-model>
  </v-container>
</template>
<script>
import axios from "axios";
import Snackbar from '../../Snackbar.vue';
import CreateEvent from './CreateEvent.vue';
import UpdateEvent from './UpdateEvent.vue';
import DeleteDialog from '../../DeleteDialog.vue';
import ImageModel from '../../ImageModel.vue';

export default {
  name: "Event",
  components: {
    Snackbar,
    ImageModel,
    CreateEvent,
    DeleteDialog,
    UpdateEvent
  },
  data: () => ({
    search: "",
    addEventModal: false,
    allEvent:[],
    imageBase:'',
    s3ImageBase: '',
    imageFullUrl: '',
    tableLoading:false,
    showDeleteModel:false,
    deleteId:'',
    deleteText:'',
    isShowImageModel: false,
    allType:['festival', 'personality', 'event'],
    allReligion:['hinduism', 'islam', 'buddhism', 'christianity', 'sikhism'],
    headers: [
      { text: "Name", value: "appName" },
      { text: "Thumbnail Url", value: "thumb" },
      { text: "Background Banner Url", value: "backgroundBanner" },
      { text: "Type", value: "type" },
      { text: "Religion", value: "religion" },
      { text: "Created at", value: "created_date" },
      { text: "Action" },
    ],
    updateEventModal:false,
    updateEventDetail:{},
  }),
  watch:{
    'allEvent':function(events){
      events.map(item => { this.allType.push(item.type); this.allReligion.push(item.religion) });
    }
  },
  computed: {},
  mounted: function () { 
    this.getEvent();
  },
  created: function () {},
  methods: {
    filterOnlyCapsText(value, search) {
      return (
        value != null &&
        search != null &&
        value.toString().toLocaleLowerCase().indexOf(search) !== -1
      );
    },
    closeEventModal(){
      this.getEvent();
      this.addEventModal = false;
      this.updateEventModal = false;
    },

    getEvent(){
      this.tableLoading = true;
      axios.get(this.Node_JS_HOST + "/api/v1/web/banner-master/event")
        .then((res) => {
          const response = res.data;
          this.allEvent = response.data.allEvent;
          this.imageBase = response.data.imageBase;
          this.s3ImageBase = response.data.s3ImageBase;
          this.tableLoading = false;
        })
        .catch((error) => {
          this.errorHandle(error);
          this.tableLoading = false;
        });
    },
    showDeleteDilog(eventDetail){
      this.deleteText = 'Are you sure, You want to delete this <b>'+ eventDetail.name +'</b> Event.';
      this.deleteId = eventDetail._id;
      this.showDeleteModel = true;
    },
    UpdateEventModel(eventDetail){
      this.updateEventModal = true;
      this.updateEventDetail = eventDetail;
    },
    deleteAction(actionType,id){
      if(actionType == 'delete'){
        axios.delete(this.Node_JS_HOST + "/api/v1/web/banner-master/event/"+id)
        .then((res) => {
          if(res){
            this.getEvent();
            this.snackbarText = 'Event Deleted';
            this.isShowSnackbar = true;
          }
        })
        .catch((error) => {
          this.errorHandle(error);
          this.snackbarText = 'Something went wrong please try again';
          this.isShowSnackbar = true;
        });
      }
      this.showDeleteModel = false;
      this.deleteId = '';
    },
    async showImageModel(imageName) {
      this.imageFullUrl = this.s3ImageBase + imageName;
      this.isShowImageModel = true
    },
  },
};
</script>
<style scoped>
  ::v-deep th {
    white-space: nowrap!important;
  }
  .w-100{
    max-width: 100px;
  }
  .w-unset{
    max-width: unset;
  }
</style>