<template>
  <div class="text-center">
     <v-dialog
        v-model="snackbar"
        width="500"
      >
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            <span class="text-h5">{{ headerText }}</span>
          </v-card-title>
       
          <v-card-text class="d-flex justify-center align-center pt-5">
          <v-img
            contain
            :lazy-src="imageUrl"
            max-height="250"
            max-width="250"
            :src="imageUrl"
          >
          </v-img></v-card-text>
        
          <v-divider></v-divider>
  
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              v-if="showDownLoad"
              color="blue darken-1"
              text
              @click="downloadImage(imageUrl)"
            >
              Download
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="hideSnackbar()"
            >
              Close
            </v-btn>
           
          </v-card-actions>
        </v-card>
      </v-dialog>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "ImageModel",
  props: {
    text: {
      type: String,
      default: '',
    },
    headerText: {
      type: String,
      default: ''
    },
    imageUrl: {
      type: String,
      default: ''
    },
    imageBuffer: {
      type: Object,
      default: null
    },
    showModel:{
      type: Boolean,
      default: false,
    },
    showDownLoad: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    snackbar:false
  }),
  watch:{
    showModel(val) {
      this.snackbar = val;
    },
  },
  computed: {},
  mounted: function () {},
  created: function () {},
  methods: {
    hideSnackbar:function() {
      this.imageUrl = '';
      this.$emit("hide");
    },
    downloadImage: async function() {
    await this.getImageBuffer()
    let buffer = Buffer.from(this.imageBuffer.data);
    let imageBase64 = buffer.toString('base64');
    const link = document.createElement("a");
    link.href = "data:image/png;base64," + imageBase64;
    link.download = "image.jpeg";
    link.click();
    },
    getImageBuffer: async function() {
      const imageName = this.imageUrl.split('event-master')[1]
       await axios.get(this.Node_JS_HOST + `/api/v1/web/banner-master/get-user-custom-file?fileName=${imageName}`)
        .then((res) => {
          const response = res.data;
          this.imageBuffer = res.data.imageBuffer
          return response
        })
        .catch((error) => {
          this.errorHandle(error);
        });
    }
  },
};
</script>