<template>
  <div class="text-center">
    <v-snackbar
      v-model="snackbar"
    >
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="hideSnackbar()"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
export default {
  name: "Snackbar",
  props: {
    text: {
      type: String,
      default: '',
    },
    showSnackbar: {
      type: Boolean,
      default: false,
    }
  },
  data: () => ({
    snackbar:false
  }),
  watch:{
    showSnackbar(val) {
      this.snackbar = val;
    },
  },
  computed: {},
  mounted: function () {
    setInterval(() => {
      this.hideSnackbar();
    },5000);
  },
  created: function () {},
  methods: {
    hideSnackbar:function(){
      this.$emit("hide");
    }
  },
};
</script>